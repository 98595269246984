<template>
  <div>
    <v-btn
      v-for="(item, i) in items.slice(0, appbar ? 3 : items.length )"
      :key="i"
      :fab="appbar"
      class="ma-1 ma-md-2"
      color="info"
      :small="!large"
      :href="(item.type === 'link' || appbar ? item.ref : '')"
      target="_blank"
      rel="noopener"
      @click="(item.type === 'link' ? '' : openLink(item.name, item.ref))"
    >
      <v-icon
        dark
        v-text="item.icon"
      />
    </v-btn>
  </div>
</template>

<script>
  export default {
    props: {
      large: {
        type: Boolean,
        default: false,
      },
      appbar: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [
        {
          name: 'Email',
          icon: 'mdi-email',
          ref: 'mailto:mail@gerhardherrera.de'
        },
        {
          name: 'MobilePhone',
          icon: 'mdi-cellphone',
          ref: 'tel:+491735716355'
        },
        {
          name: 'Phone',
          icon: 'mdi-phone-classic',
          ref: 'tel:+492234497710'
        },
        {
          name: 'Fax',
          icon: 'mdi-fax',
          ref: 'tel:+49223447652'
        },
        {
          name: 'Instagram',
          icon: 'mdi-instagram',
          ref: 'https://www.instagram.com/ktt_01/',
          type: 'link'
        },
        {
          name: 'Facebook',
          icon: 'mdi-facebook',
          ref: 'https://www.facebook.com/KTT2001',
          type: 'link'
        },
        {
          name: 'Ktt',
          icon: 'mdi-web',
          ref: 'https://www.ktt01.de',
          type: 'link'
        },
      ],
    }),

    methods: {
      openLink (name, ref){
        this.$eventHub.$emit('open-dialog', {name: name, ref: ref})
      }
    }
  }
</script>
